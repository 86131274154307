<script setup lang="ts">
const { getFormattedPrice } = usePrice();

const props = defineProps<{
  currency: string;
  price: number;
}>();

interface FreeDeliveryRules {
  freeShippingAmount: number;
  amountMissingForFreeShipping: number;
  percentMissingForFreeShipping: number;
  lowestShippingPrice: number;
  apiAlias: string;
}

const { cart } = useCart();

const freeShippingData = computed(() => {
  const freeDeliveryExtension = cart.value?.extensions
    ?.deliveryPricesConfig as FreeDeliveryRules;

  return freeDeliveryExtension;
});

const calculateProgress = computed(() => {
  return 100 - freeShippingData.value?.percentMissingForFreeShipping;
});

const missingForFreeShipping = computed(() => {
  return `${getFormattedPrice(freeShippingData.value?.amountMissingForFreeShipping.toFixed(2)) || ""}`;
});
</script>

<template>
  <div class="checkout-free-shipping-bar">
    <div
      class="checkout-free-shipping-bar__info text-m-sm md:text-sm mb-2 text-left text-[#2e8540]"
    >
      {{
        $t("checkoutFreeShipping.addForFreeShipping", {
          amount: missingForFreeShipping,
        })
      }}
    </div>

    <div
      class="checkout-free-shipping-bar__progress relative h-1 rounded overflow-hidden"
    >
      <div
        class="checkout-free-shipping-bar__progress-overlay h-full transition-[width] duration-500 absolute bg-[#2e8540]"
        :style="{ width: calculateProgress + '%' }"
      ></div>

      <div
        class="checkout-free-shipping-bar__progress-background h-full w-full bg-[#2e8540] opacity-20"
      ></div>
    </div>
  </div>
</template>
